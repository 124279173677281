export const mutations = {
  saveData(state) {
    window.localStorage.setItem("cart", JSON.stringify(state.cart));
  },
  categorySelect(state, data) {
    state.categorySelect = data;
  },
  filters(state, data) {
    console.log(data);
    state.filters = data;
  },
  // maxPriceSelect(state, data) {
  //   state.maxPriceSelect = data;
  // },
  // minCapacitySelect(state, data) {
  //   state.minCapacitySelect = data;
  // },
  // maxCapacitySelect(state, data) {
  //   state.maxCapacitySelect = data;
  // },
  // deliveyLocation(state, data) {
  //   state.deliveyLocation = data;
  // },
  paymentSent(state, data) {
    state.paymentSent = data;
  },
  updateCart(state, product) {
    state.cart = product;
    this.commit("saveData");
  },
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token, user) {
    state.status = "success";
    state.token = token;
    state.user = user;
  },
  handle_loading(state, data) {
    state.loading = data;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout(state) {
    state.status = "";
    state.token = "";
  }
};
