import axios from "@/axios.js";
import { VueIzitoast } from "vue-izitoast";
export const actions = {
  addProductToCart(context, product) {
    context.commit("pushProductToCart", product);
  },
  authRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: payload.path,
        data: payload.data,
        method: "POST"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          localStorage.removeItem("token");
          reject(err);
        });
    });
  },
  delRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;

      axios({
        url: `${payload.path}`,
        method: "DELETE",
        headers: {
          Authorization: `bearer ${token}`
        }
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) {
              dispatch('logout').then(() => {
                location.href = "/login?r=auth";
              });
            } else {
              reject(err)
            }
          } else {
            reject(err);
          }
        });
    });
  },
  putRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "PUT",
        headers: {
          Authorization: `bearer ${token}`
        }
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) {
              dispatch('logout').then(() => {
                location.href = "/login?r=auth";
              });
            } else {
              reject(err)
            }
          } else {
            reject(err);
          }
        });
    });
  },
  postRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "POST",
        headers: {
          Authorization: `bearer ${token}`
        }
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) {
              dispatch('logout').then(() => {
                location.href = "/login?r=auth";
              });
            } else {
              reject(err)
            }
          } else {
            reject(err);
          }
        });
    });
  },
  getRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      const token = getters.token;

      axios({
        url: `${payload.path}`,
        method: "GET",
        headers: {
          Authorization: `bearer ${token}`
        }
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 401) {
              dispatch('logout').then(() => {
                location.href = "/login?r=auth";
              });
            } else {
              reject(err)
            }
          } else {
            reject(err);
          }
        });
    });
  },
  logout({ commit }) {
    commit("logout");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete axios.defaults.headers.common["Authorization"];
    return Promise.resolve();
  },

  showToast({ commit }, payload) {
    commit("SHOW_TOAST", payload);
  },
  showModal({ commit }, payload) {
    commit("SHOW_MODAL", payload);
  }
};
