<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 28 28"
    :fill="inCart ? '#255e13' : 'none'"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="13.25" stroke="#255E13" stroke-width="1.5" />
    <g clip-path="url(#clip0)">
      <path
        d="M18.9037 10.8466C18.8221 10.7324 18.6997 10.666 18.5703 10.666H10.7873L10.7554 10.3326L10.7543 10.3219C10.6017 8.99822 9.60237 8 8.42969 8C8.19237 8 8 8.219 8 8.48918C8 8.75935 8.19237 8.97836 8.42969 8.97836C9.17103 8.97836 9.80303 9.60815 9.90163 10.4441L10.4124 15.786C9.96571 16.0168 9.6543 16.5294 9.6543 17.1232C9.6543 17.1272 9.65434 17.1313 9.65443 17.1354C9.65434 17.1395 9.6543 17.1435 9.6543 17.1476C9.6543 17.9568 10.2326 18.6152 10.9434 18.6152H11.1182C11.0745 18.761 11.0508 18.9173 11.0508 19.0799C11.0508 19.8756 11.6194 20.5229 12.3184 20.5229C13.0173 20.5229 13.5859 19.8756 13.5859 19.0799C13.5859 18.9173 13.5622 18.761 13.5185 18.6152H15.3721C15.3284 18.761 15.3047 18.9173 15.3047 19.0799C15.3047 19.8756 15.8733 20.5229 16.5723 20.5229C17.2712 20.5229 17.8399 19.8756 17.8399 19.0799C17.8399 18.2842 17.2712 17.6368 16.5723 17.6368H10.9434C10.7064 17.6368 10.5137 17.4174 10.5137 17.1476C10.5137 17.1435 10.5136 17.1395 10.5135 17.1354C10.5136 17.1313 10.5137 17.1272 10.5137 17.1232C10.5137 16.8534 10.7064 16.634 10.9434 16.634H16.429C17.1917 16.634 17.8917 16.0967 18.2123 15.2652C18.3077 15.0178 18.2089 14.7293 17.9916 14.6207C17.7743 14.5121 17.5208 14.6246 17.4254 14.872C17.2447 15.3407 16.8443 15.6556 16.429 15.6556H11.2644L10.8808 11.6444H18.0418L17.8315 12.7919C17.7831 13.0564 17.9321 13.3155 18.1644 13.3706C18.194 13.3777 18.2235 13.3811 18.2526 13.3811C18.4518 13.3811 18.6305 13.2225 18.6728 12.9917L18.9909 11.2551C19.0174 11.1109 18.9853 10.9609 18.9037 10.8466ZM16.5723 18.6152C16.7974 18.6152 16.9805 18.8236 16.9805 19.0799C16.9805 19.3361 16.7974 19.5446 16.5723 19.5446C16.3472 19.5446 16.1641 19.3361 16.1641 19.0799C16.1641 18.8236 16.3472 18.6152 16.5723 18.6152ZM12.3184 18.6152C12.5435 18.6152 12.7266 18.8236 12.7266 19.0799C12.7266 19.3361 12.5435 19.5446 12.3184 19.5446C12.0933 19.5446 11.9102 19.3361 11.9102 19.0799C11.9102 18.8236 12.0933 18.6152 12.3184 18.6152Z"
        :fill="inCart ? '#ffffff' : '#255E13'"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="11"
          height="12.5229"
          fill="white"
          transform="translate(8 8)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: ["inCart"],
};
</script>
