<template>
  <div v-if="viewOffline" class="offl">
    <div class="container">
      <p v-if="showTop">Offline user</p>
      <div class="mycon" v-if="showTop">
        <h4>Are you done with your selections?</h4>
        <router-link v-if="isCart" to="/finish">Finish</router-link>
        <router-link v-else to="/cart">View Cart</router-link>
      </div>
      <div v-else-if="!showTop" class="mycon">
        <div class="ttt">
          <h3 class="fin">Offline Transaction</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewOffline: false,
      isCart: false,
      showTop: true,
    };
  },
  // updated() {
  //   console.log("updated");
  // },
  watch: {
    $route(to, from) {
      // console.log("route change");
      // this.show = false;
      // console.log("Route don change");
      if (this.$route.path === "/cart") {
        // console.log("Na the path");
        this.isCart = true;
      } else {
        this.isCart = false;
      }

      if (this.$route.path === "/finish") {
        // console.log("Na the path");
        this.showTop = false;
      } else {
        this.showTop = true;
      }

      var exist = localStorage.getItem("identifier");
      if (exist) {
        // console.log("mmmm");
        this.viewOffline = true;
      } else {
        this.viewOffline = false;
        // console.log("maaaa");
      }
    },
  },
  mounted() {
    // console.log(this.$route);
    if (this.$route.path === "/cart") {
      // console.log("Na the path");
      this.isCart = true;
    } else {
      this.isCart = false;
    }

    if (this.$route.path === "/finish") {
      // console.log("Na the path");
      this.showTop = false;
    } else {
      this.showTop = true;
    }
    var exist = localStorage.getItem("identifier");
    if (exist) {
      // console.log("mmmm");
      this.viewOffline = true;
    } else {
      this.viewOffline = false;
    }
  },
};
</script>

<style scoped>
.ttt {
  width: 100%;
}
.ttt * {
  /* width: 100%; */
  display: block;
}
h3.fin {
  font-size: 2.3rem;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 2px;
  text-align: center;
  color: #255e13;
}

.offl {
  position: fixed;
  top: 0;
  left: 0;
  height: 17vh;
  width: 100%;
  background-color: #e9f7e3;
  color: #fff;
  z-index: 99999999999999999;
}
.offl .container {
  height: 100%;
}
.mycon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.mycon * {
  margin: 0;
}
.mycon h4 {
  color: #255e13;
  font-weight: 500;
}
.mycon a {
  background: #255e13;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  display: inline-block;
  padding: 10px 14px;
  transition: all 0.3s ease-in-out;
}
.mycon a:hover {
  background-color: #fdd826;
  color: #525f7f;
}
p {
  color: #121113;
  font-weight: 500;
  position: absolute;
  font-size: 0.8rem;
}
</style>