import crypto from "crypto";
export default {
  formatPrice(price) {
    var formatter = new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      maximumFractionDigits: 0
    });
    return formatter.format(price);
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  formatTime(time) {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
    var d = new Date(time);
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "am";
    if (hr > 12) {
      hr -= 12;
      ampm = "pm";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    return `${day}, ${date} ${month} ${year}`;
  },
  encrypt(text) {
    try {
      const cypher = crypto.createCipheriv(
        process.env.VUE_APP_ALGORITHM,
        Buffer.from(process.env.VUE_APP_AESKEY),
        process.env.VUE_APP_IVKEY
      );

      let encrypted = cypher.update(JSON.stringify(text));
      encrypted = Buffer.concat([encrypted, cypher.final()]);
      return encrypted.toString("hex");
    } catch (error) {
      return null;
    }
  },
  decrypt(text) {
    try {
      const encryptedText = Buffer.from(text, "hex");
      const decipher = crypto.createDecipheriv(
        process.env.VUE_APP_ALGORITHM,
        Buffer.from(process.env.VUE_APP_AESKEY),
        process.env.VUE_APP_IVKEY
      );
      let decrypted = decipher.update(encryptedText);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      return JSON.parse(decrypted.toString());
    } catch (error) {
      return null;
    }
  }
};
